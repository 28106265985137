import { SVGProps } from "react";

export const LoadingSimpleSpinner = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="#ffffff"
        strokeWidth="2.5"
        strokeLinecap="round"
        fill="none"
        strokeDasharray="90"
        strokeDashoffset="150"
      >
        <animateTransform
          attributeName="transform"
          dur="0.9s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        />
      </circle>
    </svg>
  );
};
