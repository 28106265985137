import { memo, useMemo, useRef } from "react";
import pageChangerControlsAtom from "@recoil/pageChangerControls/atom";
import { useRecoilValue } from "recoil";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { LoadingSimpleSpinner } from "../components/Loading/LoadingSimpleSpinner";

interface PageChangeProps {
  chat?: boolean;
  miniSide?: boolean;
  loading?: boolean;
  isMobile?: boolean;
}

const PageChange = ({ loading = false }: PageChangeProps) => {
  const manualControl = useRecoilValue(pageChangerControlsAtom);
  const container = useRef<HTMLDivElement>(null);

  const isVisible = useMemo(() => {
    if (manualControl?.manual) {
      return manualControl?.isLoading;
    }

    return loading;
  }, [loading, manualControl?.isLoading, manualControl?.manual]);

  useGSAP(
    () => {
      if (isVisible) {
        return gsap.to(container.current, {
          duration: 0.2,
          ease: "power1.in",
          opacity: 1,
        });
      }

      return gsap.to(container.current, {
        duration: 0.35,
        opacity: 0,
        ease: "power1.out",
        pointerEvents: "none",
      });
    },
    { scope: container, dependencies: [isVisible] }
  );

  return (
    <div
      ref={container}
      className="fixed z-[90] w-full h-screen top-[68px] overflow-hidden bg-primary-background-color"
    >
      <div className="flex justify-center items-center w-full h-full">
        <LoadingSimpleSpinner className="h-14 w-14 animate-spin text-white" />
      </div>
    </div>
  );
};

export default memo(PageChange);
